import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import type { Experiments } from '@wix/yoshi-flow-editor';
import { IFlowApiAdapter } from './flow-api-adapter/types';
import { FlowApiAdapter } from './flow-api-adapter/flow-api-adapter';

export function createWixSdkAdapter(
  controllerConfig: IWidgetControllerConfig,
  experiments: Experiments,
): IFlowApiAdapter {
  return new FlowApiAdapter(
    controllerConfig.wixCodeApi as any,
    controllerConfig.platformAPIs,
    controllerConfig.appParams,
    experiments,
  );
}
