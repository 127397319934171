import type { Experiments } from '@wix/yoshi-flow-editor';
import { BOOKINGS_APP_DEF_ID } from '../../../../../utils/flow-api-adapter/consts';
import { bookingsUouBookFlowBookingFormClickNext } from '@wix/bi-logger-wixboost-ugc/v2';
import { ServiceData, SlotService } from '../../../../../utils/state/types';
import { CreateActionParams } from '../../actions';
import { FormStatus } from '../../../../../types/form-state';
import { FormClickNextAction } from '../../../../../types/biLoggerTypes';
import { filterQueryParams } from '../../../../../api/PageAPIAdapter/URLAdapter/URLAdapter';
import { getSelectedServicesForBuyPlan } from '../../../../../utils/payment';
import { FormSelectedSlot } from '../../../../../types/formSelectedSlots';
import { mapToArray } from '../../../../../utils';
import { FormApi } from '../../../../../api/FormApi';
import { ExperimentsConsts } from '../../../../../consts/experiments';
import { IFlowApiAdapter } from '../../../../../utils/flow-api-adapter/types';

export default async function handleChoosePlanFlow(
  {
    getControllerState,
    context: {
      wixSdkAdapter,
      bookingsDataCapsule,
      biLogger,
      experiments,
      formApi,
    },
  }: CreateActionParams,
  serviceData: ServiceData,
  formSelectedSlot: FormSelectedSlot,
) {
  const [state, setState] = getControllerState();
  const { editorContext } = state;

  if (editorContext.isDummy) {
    await formApi.navigateToPricePlanPackagePicker({});
  } else {
    biLogger?.report(
      bookingsUouBookFlowBookingFormClickNext({
        action: FormClickNextAction.ChooseAPlan,
      }),
    );
    await purchasePricingPlan(
      serviceData,
      formSelectedSlot,
      formApi,
      wixSdkAdapter,
      experiments,
    );
  }

  setState({ status: FormStatus.IDLE });
}

async function purchasePricingPlan(
  serviceData: ServiceData,
  formSelectedSlot: FormSelectedSlot,
  formApi: FormApi,
  wixSdkAdapter: IFlowApiAdapter,
  experiments: Experiments,
) {
  const isFormPaymentOptionNewDropdownEnabled = experiments.enabled(
    ExperimentsConsts.FormPaymentOptionNewDropdown,
  );

  if (!isFormPaymentOptionNewDropdownEnabled && serviceData.isSingleService) {
    return OLD_purchasePricingPlan(serviceData, wixSdkAdapter);
  }

  const slotServices = serviceData.slotServices;
  const queryParams = getQueryParams(wixSdkAdapter);
  const { referral } = queryParams;

  const services = mapToArray<SlotService>(slotServices)
    .map((slotService) => slotService.service.id)
    .join(',');

  const planIds = getSelectedServicesForBuyPlan(slotServices).map(
    (slotService) => slotService.selectedPaymentOption.id,
  );

  const successStatus: any = {
    navigation: {
      type: 'section',
      options: {
        appDefinitionId: BOOKINGS_APP_DEF_ID,
        sectionId: 'Booking Form',
        state: '',
        queryParams: {
          ...filterQueryParams(queryParams),
          referral,
          timezone: formSelectedSlot.timezone,
          service: services,
        },
      },
    },
  };

  if (planIds.length === 1) {
    return formApi.navigateToPricePlanCheckout({
      planId: planIds[0],
      successStatus,
    });
  }

  return formApi.navigateToPricePlanPackagePicker({
    planIds,
    checkout: {
      successStatus,
    },
  });
}

function OLD_purchasePricingPlan(
  serviceData: ServiceData,
  wixSdkAdapter: IFlowApiAdapter,
) {
  const slotService = mapToArray<SlotService>(serviceData.slotServices)[0];

  const planIds = slotService.service.payment.pricingPlanInfo?.pricingPlans;

  const queryParams = getQueryParams(wixSdkAdapter);

  const { referral } = queryParams;

  return wixSdkAdapter.navigateToPricingPlan({
    redirectTo: { sectionId: 'Booking Form', relativePath: '' },
    planIds: planIds!,
    maxStartDate: slotService.nestedSlot.startDate!,
    queryParams: {
      ...filterQueryParams(queryParams),
      referral,
      timezone: slotService.nestedSlot.timezone,
      service: slotService.service.id,
    },
  });
}

export function getQueryParams(wixSdkAdapter: IFlowApiAdapter): {
  [key: string]: string;
} {
  return wixSdkAdapter.getUrlQueryParams();
}
