import { FormApiContext, withErrorBoundary } from '../utils';
import { LineItem as TotalsCalculatorLineItem } from '@wix/ambassador-totals-calculator/http';
import { CalculateTotalsResponse } from '@wix/ambassador-totals-calculator/types';
import { OnError } from '../../types';
import {
  mapCouponServerError,
  mapCouponServerErrorsAndReporter,
} from '../../../utils/errors/errors';
import { ServerErrorType } from '../../../types/errors';

export type CalculateTotalPriceArgs = {
  lineItems: TotalsCalculatorLineItem[];
  couponCode?: string;
  email?: string;
  onError?: OnError;
};
export const calculateTotalPrice = async ({
  reportError,
  authorization,
  totalsCalculatorServer,
  lineItems,
  couponCode,
  email,
  onError,
}: CalculateTotalPriceArgs &
  FormApiContext): Promise<CalculateTotalsResponse> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const calculateTotalsResponse = await totalsCalculatorServer
          .TotalsCalculator()({
            Authorization: authorization,
          })
          .calculateTotals({
            calculateTax: true,
            couponCode,
            buyerEmail: email,
            lineItems,
          });

        const couponError =
          calculateTotalsResponse?.calculationErrors?.couponCalculationError
            ?.applicationError?.code ||
          calculateTotalsResponse?.calculationErrors?.couponCalculationError
            ?.validationError?.fieldViolations?.[0].description;
        if (couponError) {
          throw mapCouponServerError(couponError);
        }
        return calculateTotalsResponse;
      },
      mapError: (e) => mapCouponServerErrorsAndReporter(JSON.stringify(e)),
      fallback: {},
    },
    reportError,
  );

  if (error) {
    onError?.(error as unknown as ServerErrorType);
  }

  return data;
};
