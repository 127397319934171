import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import { ServiceType } from '@wix/bookings-uou-types';
import { FormNestedSlot } from '../../types/formSelectedSlots';

type TimeSegment = {
  startDate: Date;
  endDate: Date;
  resourceName?: string | null;
};

const mapSlotToTimeSegment = (slot: {
  startDate?: string | null;
  endDate?: string | null;
  resource?: { id?: string; name?: string | null };
}): TimeSegment => ({
  startDate: new Date(slot.startDate!),
  endDate: new Date(slot.endDate!),
  ...(slot.resource ? { resourceName: slot.resource?.name } : {}),
});

export const findOverlappingResource = (
  currentSlots: FormNestedSlot[],
  bookingsLineItemOptions: BookingsLineItemOption[],
): string | null | undefined => {
  const slotResources = currentSlots.map((slot) => slot.resource?.id);
  const sameStaffBookingsTimeSegments = bookingsLineItemOptions
    .filter(
      (itemOption) =>
        itemOption.slot?.resource?.id &&
        slotResources.includes(itemOption.slot.resource.id) &&
        itemOption.tags?.includes(ServiceType.INDIVIDUAL),
    )
    .map((itemOption) => mapSlotToTimeSegment(itemOption.slot!))
    .concat(currentSlots.map(mapSlotToTimeSegment));

  sameStaffBookingsTimeSegments.sort(
    (a: TimeSegment, b: TimeSegment) =>
      a.startDate.getTime() - b.startDate.getTime(),
  );

  for (let i = 0; i < sameStaffBookingsTimeSegments.length - 1; i++) {
    if (
      sameStaffBookingsTimeSegments[i].endDate.getTime() >
      sameStaffBookingsTimeSegments[i + 1].startDate.getTime()
    ) {
      return sameStaffBookingsTimeSegments[i].resourceName;
    }
  }
};
