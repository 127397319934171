import { ServerErrorType } from '../../../types/errors';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';

export type AreCouponsAvailableForServiceArgs = {
  onError: OnError;
};
export const areCouponsAvailableForService = async ({
  reportError,
  couponsServer,
  authorization,
  onError,
}: AreCouponsAvailableForServiceArgs & FormApiContext): Promise<boolean> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const { hasCoupons } = await couponsServer
          .CouponsV2()({ Authorization: authorization })
          .hasCoupons({});
        return hasCoupons;
      },
      mapError: (e) => ({
        error: ServerErrorType.COUPON_SERVICE_UNAVAILABLE,
        shouldReport: true,
      }),
      fallback: false,
    },
    reportError,
  );

  if (error) {
    onError(error);
  }
  return data;
};
