import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getURLAdapter,
  getParsedAppSectionParams,
  getQueryValueByName,
  QueryParamName,
} from './URLAdapter';

export const extractAppSection = (flowAPI: ControllerFlowAPI) => {
  const appSectionParams = getQueryValueByName(
    flowAPI.controllerConfig.wixCodeApi.location.query,
    QueryParamName.APP_SECTION_PARAMS,
  );

  if (!appSectionParams) {
    return;
  }

  const query = {
    ...getParsedAppSectionParams(appSectionParams),
    ...flowAPI.controllerConfig.wixCodeApi.location.query,
  };

  flowAPI.controllerConfig.wixCodeApi.location.queryParams.add(query);
  flowAPI.controllerConfig.wixCodeApi.location.queryParams.remove([
    QueryParamName.APP_SECTION_PARAMS,
  ]);
};

export const getInputServiceType = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): 'class' | 'course' | 'appointment' | undefined => {
  const urlAdapter = getURLAdapter({ flowAPI });

  const sessionId = urlAdapter.getSessionId();
  const serviceId = urlAdapter.getServiceId();
  const resourceId = urlAdapter.getResourceId();
  const startDate = urlAdapter.getStartDate();
  const endDate = urlAdapter.getEndDate();

  if (sessionId) {
    return 'class';
  }

  if (serviceId && !(resourceId && startDate && endDate)) {
    return 'course';
  }

  if (serviceId && resourceId && startDate && endDate && !sessionId) {
    return 'appointment';
  }

  return undefined;
};
