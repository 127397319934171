import { BookedSlot } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormNestedSlot } from '../../types/formSelectedSlots';

export const mapFormNestedSlotToServerSlot = (
  slot?: FormNestedSlot,
): BookedSlot | undefined => {
  if (!slot) {
    return undefined;
  }

  return {
    startDate: slot.startDate === null ? undefined : slot.startDate,
    endDate: slot.endDate === null ? undefined : slot.endDate,
    timezone: slot.timezone === null ? undefined : slot.timezone,
    sessionId: slot.sessionId === null ? undefined : slot.sessionId,
    location: slot.location,
    resource: slot.resource,
    scheduleId: slot.scheduleId === null ? undefined : slot.scheduleId,
    serviceId: slot.serviceId === null ? undefined : slot.serviceId,
  };
};
