export const DEFAULT_MODAL_DIMENSIONS = { height: 380, width: 580 };
export const PREVIEW_MODAL_DIMENSIONS = { height: 600, width: 600 };

export const PRICING_PLAN_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';

export const PRICING_PLAN_SECTION_ID = 'membership_plan_picker_tpa';

export const BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';

export const BOOKINGS_CHECKOUT_SECTION_ID = 'book_checkout';

export const BOOKINGS_SCHEDULER_SECTION_ID = 'scheduler';

export const BOOKINGS_LIST_SECTION_ID = 'bookings_list';

export const ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

export const ECOM_CART_SECTION_ID = 'shopping_cart';

export const DEFAULT_LOCALE = 'en-US';

export const DEFAULT_LANGUAGE = 'en';
