import { PaymentType, OfferedAsType } from '@wix/bookings-uou-types';
import { ActiveFeatures } from '@wix/bookings-uou-types/src';
import { FormService } from '../mappers/serviceMapper/service.mapper';
import {
  IncompleteFormNestedSlot,
  IncompleteFormSelectedSlot,
} from '../../types/types';
import { IFlowApiAdapter } from '../flow-api-adapter/types';

export interface CanBookResult {
  canBook: boolean;
  reason?: Reason;
}

export interface Reason {
  premiumError?: boolean;
  pricingPlanError?: boolean;
  isServiceConnectedToPricingPlans?: boolean;
  isPricingPlanInstalled?: boolean;
}

const Features = {
  PAYMENTS: 'payments',
  APPOINTMENT: 'individual',
  CLASS: 'groups',
  COURSE: 'course',
  REMINDERS: 'reminders',
  SMS_REMINDERS: 'smsReminders',
  STAFF: 'staffMembers',
};

const enum pricingPlanConst {
  PAGE_NOT_INSTALLED = 'PageNotInstalled',
  NO_PLANS_ASSIGNED_TO_OFFERING = 'NoPlansAssignedToOffering',
}

export class ValidationBase {
  constructor(
    protected readonly wixSdkAdapter: IFlowApiAdapter,
    protected readonly service: FormService,
    protected readonly activeFeatures: ActiveFeatures,
  ) {}

  async canBook(): Promise<CanBookResult> {
    const [isPricingPlanInstalled, couldBePremium] = await Promise.all([
      this.wixSdkAdapter.isPricingPlanInstalled(),
      this.couldBePremium(),
    ]);
    const couldBePricingPlan = this.couldBePricingPlan(isPricingPlanInstalled);
    return {
      canBook: couldBePremium && couldBePricingPlan.canBook,
      reason: {
        premiumError: !couldBePremium,
        pricingPlanError: !couldBePricingPlan.canBook,
        isServiceConnectedToPricingPlans:
          couldBePricingPlan.isServiceConnectedToPricingPlans,
        isPricingPlanInstalled,
      },
    };
  }

  protected couldBePricingPlan(isPricingPlanInstalled: boolean): {
    canBook: boolean;
    isServiceConnectedToPricingPlans: boolean;
  } {
    const isServiceConnectedToPricingPlans = !this.hasNoPricingPlans();

    const serviceOfferedAsPricingPlan = this.isServiceOfferedAsPricingPlan(
      isPricingPlanInstalled,
    );
    return {
      canBook:
        !serviceOfferedAsPricingPlan ||
        (isServiceConnectedToPricingPlans && isPricingPlanInstalled),
      isServiceConnectedToPricingPlans,
    };
  }

  protected isServiceOfferedAsPricingPlan(isPricingPlanInstalled: boolean) {
    return (
      this.getOfferedAs(isPricingPlanInstalled).indexOf(
        OfferedAsType.PRICING_PLAN,
      ) > -1
    );
  }

  protected getOfferedAs(isPricingPlanInstalled: boolean): OfferedAsType[] {
    if (
      this.service.payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0 &&
      this.service.payment.offeredAs.indexOf(OfferedAsType.PRICING_PLAN) >= 0
    ) {
      if (this.hasNoPricingPlans() || !isPricingPlanInstalled) {
        return [OfferedAsType.ONE_TIME];
      }
    }
    return this.service.payment.offeredAs;
  }

  protected isOnline() {
    return (
      this.service.payment.paymentDetails.paymentType === PaymentType.ONLINE
    );
  }

  protected isOffline() {
    return (
      this.service.payment.paymentDetails.paymentType === PaymentType.OFFLINE
    );
  }

  protected hasNoPricingPlans() {
    return (
      (this.service.payment.pricingPlanInfo?.pricingPlans?.length || 0) === 0
    );
  }

  protected async couldBePremium(): Promise<boolean> {
    return this.isFeatureEnabled();
  }

  protected isFeatureEnabled() {
    const featureName = Features[this.service.type as keyof typeof Features];
    if (this.activeFeatures.hasOwnProperty(featureName)) {
      // @ts-expect-error
      return this.activeFeatures[featureName];
    }
    return false;
  }
}

export const getNotifyPricingPlanRequest = (
  service: FormService,
  reason: Reason,
) => {
  const reasons = [];
  if (!reason.isPricingPlanInstalled) {
    reasons.push(pricingPlanConst.PAGE_NOT_INSTALLED);
  }
  if (!reason.isServiceConnectedToPricingPlans) {
    reasons.push(pricingPlanConst.NO_PLANS_ASSIGNED_TO_OFFERING);
  }
  const offeringId = service.id;
  return { reasons, offeringId };
};

export const isServiceExist = (
  formSelectedSlot?: IncompleteFormSelectedSlot,
): boolean => {
  return !!formSelectedSlot?.nestedSlots.filter(
    (slot: IncompleteFormNestedSlot) => slot.serviceId,
  ).length;
};
