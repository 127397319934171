import type { ActiveFeatures } from '@wix/bookings-uou-types';
import { DayfulBookValidations } from './dayful-validations';
import { getNotifyPricingPlanRequest, ValidationBase } from './Validations';
import type { FormApi } from '../../api/FormApi';
import { ServiceData, SlotService } from '../state/types';
import { mapToArray } from '..';
import { IFlowApiAdapter } from '../flow-api-adapter/types';

export const canCompleteBoooking = async ({
  formApi,
  isDayful,
  wixSdkAdapter,
  activeFeatures,
  serviceData,
}: {
  formApi: FormApi;
  isDayful: boolean;
  wixSdkAdapter: IFlowApiAdapter;
  serviceData: ServiceData;
  activeFeatures: ActiveFeatures;
}) => {
  const results = await Promise.all(
    mapToArray<SlotService>(serviceData.slotServices).map(
      async (slotService) => {
        const bookingsValidation = isDayful
          ? new DayfulBookValidations(
              wixSdkAdapter,
              slotService.service,
              activeFeatures,
            )
          : new ValidationBase(
              wixSdkAdapter,
              slotService.service,
              activeFeatures,
            );

        const { canBook, reason } = await bookingsValidation.canBook();
        return {
          service: slotService.service,
          canBook,
          reason,
        };
      },
    ),
  );

  if (results.some((result) => !result.canBook)) {
    if (!isDayful) {
      const nonPremiumEnrollmentAttempt = results.find(
        (result) => result.reason?.premiumError,
      );
      const nonPricingPlanEnrollmentAttempt = results.find(
        (result) => result.reason?.pricingPlanError,
      );

      if (nonPremiumEnrollmentAttempt) {
        await formApi.notifyOwnerNonPremiumEnrollmentAttempt();
      } else if (nonPricingPlanEnrollmentAttempt) {
        await formApi.notifyOwnerNonPricingPlanEnrollmentAttempt(
          getNotifyPricingPlanRequest(
            nonPricingPlanEnrollmentAttempt.service,
            nonPricingPlanEnrollmentAttempt.reason!,
          ),
        );
      }
    }
    await wixSdkAdapter.openUoUPremiumModal(
      // All the services are the same type so we can take the first one
      mapToArray<SlotService>(serviceData.slotServices)[0].service.type,
      'bookings-form',
      isDayful,
    );

    return false;
  }

  return true;
};
