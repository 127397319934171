import { query } from '@wix/ambassador-bookings-v2-booking/http';
import { FormApiContext, QueryBookingsArgs } from '../utils';
import { Booking } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export const queryBookings = async ({
  bookingIds,
  httpClient,
}: QueryBookingsArgs & FormApiContext): Promise<Booking[]> => {
  let listResponse;

  try {
    listResponse = await httpClient.request(
      query({
        query: {
          filter: {
            id: bookingIds,
          },
        },
      }),
    );

    return listResponse?.data?.bookings || [];
  } catch (e) {
    return [];
  }
};
