import { FormStatus } from '../../../../../types/form-state';
import { CreateActionParams } from '../../actions';

export async function handleTemplateFlow({
  getControllerState,
  context: { wixSdkAdapter },
}: CreateActionParams) {
  const [, setState] = getControllerState();
  setState({
    status: FormStatus.IDLE,
  });
  await wixSdkAdapter.openEcomCheckoutTemplateModal();
}
