import { ServiceInfo } from '../../../api/types';
import {
  ServiceType,
  Payment,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { CommonImage } from '../../../types/ambassador/bookings/ambassador-services-catalog';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import {
  FormNestedSlot,
  FormSelectedSlot,
} from '../../../types/formSelectedSlots';
import {
  BookedLocation,
  LocationType as BookingsLocationType,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { PaymentMethod } from '../../../types/types';
import { mapServiceLocations } from './utils/location.mapper';
import { ServicePayment, mapServicePayment } from './utils/payment.mapper';

export type FormLocation = {
  id?: string | null;
  name?: string | null;
  address?: string | null;
  locationType: BookingsLocationType;
};

export interface StaffMember {
  id: string;
  name: string;
  slug?: string;
}

export type FormService = {
  id: string;
  name: string;
  staffMembers: StaffMember[];
  location: FormLocation;
  isPendingApprovalFlow: boolean;
  isWaitingListFlow: boolean;
  paymentTypes: PaymentMethod[];
  type: ServiceType;
  scheduleId: string;
  totalNumberOfSessions: number;
  payment: ServicePayment;
  maxNumberOfParticipants: number;
  maxNumberOfParticipantsWithoutPP?: number;
  images?: CommonImage[];
  policyDescription?: string;
  policyId?: string;
  includesConferenceOption?: boolean;
  availableSpots?: number;
  hasCancellationFee?: boolean;
  hasSaveCreditCardPolicy?: boolean;
};

const getOccupiedSpotsForSlotFromCart = ({
  sessionId,
  scheduleId,
  bookingsLineItemOptions,
}: {
  sessionId?: string;
  scheduleId?: string;
  bookingsLineItemOptions: BookingsLineItemOption[];
}) => {
  return bookingsLineItemOptions.reduce((acc, next) => {
    if (
      (sessionId && next.slot?.sessionId === sessionId) ||
      (scheduleId && next.scheduleId === scheduleId)
    ) {
      return acc + (next.numberOfParticipants || 1);
    }
    return acc;
  }, 0);
};

export const mapCatalogServiceToService = ({
  serviceInfo,
  formSelectedSlot,
  memberships,
  numberOfSessions,
  isDynamicPriceEnabled = true,
  bookingsLineItemOptions,
  isCart = false,
  slot,
}: {
  serviceInfo: ServiceInfo;
  formSelectedSlot: FormSelectedSlot;
  memberships?: ListEligibleMembershipsResponse;
  numberOfSessions: number;
  isDynamicPriceEnabled?: boolean;
  bookingsLineItemOptions?: BookingsLineItemOption[];
  isCart?: boolean;
  slot: FormNestedSlot;
}): FormService => {
  const activeService = serviceInfo.service!;
  const activeSchedule = activeService.schedule!;
  const scheduleId = activeSchedule.id!;
  const serviceType = activeService.type!;
  const slotLocation: Maybe<BookedLocation> = slot?.location;
  const location: FormLocation = mapServiceLocations(
    activeService,
    slotLocation,
  );

  const paymentOptions: Payment = activeService?.payment!;
  const paymentTypes: PaymentMethod[] = mapPaymentOptionsToPaymentTypes(
    paymentOptions,
    memberships,
  );

  const payment = mapServicePayment(activeService);

  const staffMembers = serviceInfo.staffMembers
    .filter((staffMember) =>
      serviceType === ServiceType.COURSE
        ? true
        : slot?.resource?.id === staffMember.id,
    )
    .map((staffMember) => {
      return {
        id: staffMember.id!,
        name: staffMember.name,
      };
    });

  const occupiedSpotsFromCart = bookingsLineItemOptions
    ? getOccupiedSpotsForSlotFromCart({
        scheduleId,
        sessionId: slot?.sessionId,
        bookingsLineItemOptions,
      })
    : 0;

  const availableSpots = Math.max(
    0,
    (formSelectedSlot.openSpots ?? 0) - occupiedSpotsFromCart,
  );
  const maxParticipantsPerBooking =
    serviceInfo?.service?.bookingPolicy?.participantsPolicy
      ?.maxParticipantsPerBooking ?? 0;

  const maxNumberOfParticipants = getMaxNumberOfParticipants({
    availableSpots,
    maxParticipantsPerBooking,
    memberships,
  });

  const maxNumberOfParticipantsWithoutPP = getMaxNumberOfParticipants({
    availableSpots,
    maxParticipantsPerBooking,
  });

  const isPendingApprovalFlow =
    !!serviceInfo.service.onlineBooking?.requireManualApproval;

  const name = getServiceName(activeService)!;

  const images = isCart
    ? [getServiceImageWithAltText(activeService) as CommonImage]
    : undefined;

  const hasCancellationFee =
    activeService.bookingPolicy?.cancellationFeePolicy?.enabled;

  const hasSaveCreditCardPolicy =
    activeService.bookingPolicy?.saveCreditCardPolicy?.enabled;

  return {
    id: activeService.id!,
    name,
    payment,
    type: serviceType || ServiceType.APPOINTMENT,
    staffMembers,
    paymentTypes,
    location,
    totalNumberOfSessions: numberOfSessions,
    isPendingApprovalFlow,
    isWaitingListFlow: !!activeService.bookingPolicy?.waitlistPolicy?.enabled,
    scheduleId,
    maxNumberOfParticipants,
    ...(isDynamicPriceEnabled
      ? {
          maxNumberOfParticipantsWithoutPP,
        }
      : {}),
    ...(images ? { images } : {}),
    policyDescription:
      activeService.bookingPolicy?.customPolicyDescription?.description,
    policyId: activeService.bookingPolicy?.id,
    includesConferenceOption: !!activeService.conferencing?.enabled,
    availableSpots,
    hasCancellationFee,
    hasSaveCreditCardPolicy,
  };
};

const mapPaymentOptionsToPaymentTypes = (
  payment: Payment,
  memberships?: ListEligibleMembershipsResponse,
): PaymentMethod[] => {
  const paymentTypes = [];
  if (payment?.options?.online) {
    paymentTypes.push(PaymentMethod.ONLINE);
  }
  if (payment?.options?.inPerson) {
    paymentTypes.push(PaymentMethod.OFFLINE);
  }
  if (
    memberships?.eligibleMemberships?.length &&
    payment?.options?.pricingPlan
  ) {
    paymentTypes.push(PaymentMethod.MEMBERSHIP);
  }
  return paymentTypes;
};

const getMaxNumberOfParticipants = ({
  availableSpots,
  maxParticipantsPerBooking,
  memberships,
}: {
  maxParticipantsPerBooking: number;
  availableSpots: number;
  memberships?: ListEligibleMembershipsResponse;
}) => {
  const remainingCredits: Maybe<number[]> = memberships?.eligibleMemberships
    ?.filter((membership) => membership?.credits?.remaining)
    .map((membership) => membership!.credits!.remaining!);

  const maxRemainingCredits = remainingCredits?.length
    ? Math.max(...remainingCredits!)
    : maxParticipantsPerBooking;

  return Math.min(
    maxParticipantsPerBooking,
    availableSpots,
    maxRemainingCredits,
  );
};

const getServiceName = (service: Service) => {
  return service.name;
};

const getServiceImageWithAltText = (service: Service) => {
  const image = service.media?.mainMedia?.image;
  if (image) {
    return {
      ...service.media?.mainMedia?.image,
      altText: getServiceName(service),
    };
  }
};
