import { createSlotVeloAPIFactory } from '@wix/widget-plugins-ooi/velo';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/types';
import { PlatformIdentifiers } from '../../../../types/types';

export type UpsellPluginSendBookingIdsAction = (bookingIds: string[]) => void;

export function createUpsellPluginSendBookingIdsAction({
  controllerConfig,
}: ActionFactoryParams<
  FormState,
  FormContext
>): UpsellPluginSendBookingIdsAction {
  return async (bookingIds) => {
    const slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
    const slotAPI = slotAPIFactory.getSlotAPI(
      PlatformIdentifiers.BOOKINGS_FORM_CART_MODAL_SLOT_ID,
    ) as any;

    slotAPI.bookingIds = bookingIds;
  };
}
