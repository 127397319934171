import { CreateActionParams } from '../actions';

export type OnCouponCheckboxCheck = (checked: boolean) => void;

export function createOnCouponCheckboxCheckAction({
  getControllerState,
}: CreateActionParams): OnCouponCheckboxCheck {
  return (checked) => {
    const [state, setState] = getControllerState();

    setState({
      couponInfo: { ...state.couponInfo, isCouponCheckboxChecked: checked },
    });
  };
}
