import { CreateActionParams } from '../actions';
import { ServiceData } from '../../../../utils/state/types';
import { PaymentMethod, PaymentOption } from '../../../../types/types';

export type SetDefaultPaymentOptions = (dataToUpdate: {
  [key: string]: {
    selectedPaymentOption: PaymentOption;
    selectedPaymentType: PaymentMethod;
  };
}) => void;

export function createSetDefaultPaymentOptionsAction({
  getControllerState,
}: CreateActionParams): SetDefaultPaymentOptions {
  return async (dataToUpdate: {
    [key: string]: {
      selectedPaymentOption: PaymentOption;
      selectedPaymentType: PaymentMethod;
    };
  }) => {
    const [state, setState] = getControllerState();
    const updatedServiceData: ServiceData = { ...state.serviceData };

    Object.keys(dataToUpdate).forEach((serviceIdentifier) => {
      const { selectedPaymentOption, selectedPaymentType } =
        dataToUpdate[serviceIdentifier];

      updatedServiceData.slotServices[serviceIdentifier] = {
        ...updatedServiceData.slotServices[serviceIdentifier],
        selectedPaymentOption,
        selectedPaymentType,
      };
    });

    setState({ serviceData: updatedServiceData });
  };
}
