import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  ActionsFactory,
  SetState,
  StateChangeCallback,
  GetControllerState,
} from './ControlledComponent.types';
import { FormApi } from '../../api/FormApi';

export async function createControlledComponent<
  ControllerStateType,
  ControllerActionTypes,
  ContextType,
>({
  flowApi,
  controllerConfig,
  initialState,
  actionsFactory,
  context,
  formApi,
}: {
  flowApi: ControllerFlowAPI;
  controllerConfig: IWidgetControllerConfig;
  initialState: ControllerStateType;
  actionsFactory: ActionsFactory<
    ControllerStateType,
    ControllerActionTypes,
    ContextType
  >;
  context: ContextType;
  formApi: FormApi;
}): Promise<{
  onStateChange: (callback: StateChangeCallback<ControllerStateType>) => void;
  render: (
    propsToUpdate: Partial<
      ControllerStateType | { actions: ControllerActionTypes }
    >,
  ) => void;
  actions: ControllerActionTypes;
}> {
  const { setProps } = controllerConfig;
  const stateChangedListeners: StateChangeCallback<ControllerStateType>[] = [];

  const stateProxy = new Proxy<any>(initialState, {
    get(target, prop) {
      return target[prop];
    },
    set(target, prop, value) {
      if (controllerConfig?.wixCodeApi?.location?.query?.['tracing-actions']) {
        logActionDetails(target, prop, value);
      }
      if (
        typeof prop === 'string' &&
        (Array.isArray(target[prop]) || typeof target[prop] === 'object') &&
        target[prop] !== null
      ) {
        target[prop] = Array.isArray(target[prop])
          ? [...target[prop], ...value]
          : { ...target[prop], ...value };
      } else {
        target[prop] = value;
      }
      return true;
    },
  });

  const updateState = (stateToUpdate: Partial<ControllerStateType>) => {
    Object.assign(stateProxy, stateToUpdate);
  };

  const onStateChange = (
    callback: StateChangeCallback<ControllerStateType>,
  ) => {
    stateChangedListeners.push(callback);
  };

  const notifyStateChangedListeners = (newState: ControllerStateType) => {
    stateChangedListeners.forEach((listener) => listener(stateProxy));
  };

  const setState: SetState<ControllerStateType> = (
    stateToUpdate: Partial<ControllerStateType>,
  ) => {
    updateState(stateToUpdate);
    render(stateToUpdate);
    notifyStateChangedListeners(stateProxy);
  };

  const getControllerState: GetControllerState<ControllerStateType> = () => {
    return [stateProxy, setState];
  };

  const controllerActions = actionsFactory({
    getControllerState,
    context,
    flowApi,
    controllerConfig,
  });

  const render = (
    propsToUpdate: Partial<
      ControllerStateType | { actions: ControllerActionTypes }
    > = stateProxy,
  ) => {
    setProps({
      ...propsToUpdate,
    });
  };

  const localeTranslations = await formApi.fetchLocaleData();

  render({
    ...initialState,
    _translations: { ...flowApi.translations.all, ...localeTranslations },
    actions: controllerActions,
    fitToContentHeight: true,
  });

  return { onStateChange, render, actions: controllerActions };
}

function extractLastPathValue(inputString: string) {
  const match = /[^/]+$/g.exec(inputString);
  return match ? match[0] : null;
}

const logActionDetails = (target: any, prop: any, value: any) => {
  const actionName = getCallingFileName();

  const theme = 'color: #7cb46b; font-size: 12px; font-weight: normal;';
  console.groupCollapsed(
    `%c The state %c${prop}%c has been updated by - %c${
      actionName || '(see trace details inside)'
    }`,
    theme,
    `${theme} font-weight: bold; font-size: 13px;`,
    theme,
    `${theme} font-weight: bold; font-size: 13px;`,
  );
  console.log(
    '%c Current State : %o',
    'color: #6497b1; font-weight: bold;',
    target,
  );
  console.log(
    '%c State to Update : %o',
    'color: #6497b1; font-weight: bold;',
    prop,
  );
  console.log(
    '%c Value to update : %o',
    'color: #6497b1; font-weight: bold;',
    value,
  );
  console.groupCollapsed(
    '%c Stuck Trace',
    'color: #6497b1; font-weight: bold;',
  );
  console.trace();
  console.groupEnd();
  console.groupEnd();
};

const getCallingFileName = (): string | null => {
  const error: { stack?: string } = {};
  Error.captureStackTrace(error);
  const stackTrace = error.stack || '';

  const lines = stackTrace.split('\n');

  const evalLine = lines.find(
    (line) => line.includes('eval') && !line.includes('ControlledComponent.ts'),
  );

  const match = /\(([^)]+)\)/.exec(evalLine || '');

  return match ? extractLastPathValue(match[1]) : null;
};
