import { CreateActionParams } from '../actions';
import { FormStatus } from '../../../../types/form-state';

export type OnEditCollapseData = () => void;

export function createOnEditCollapseDataAction({
  getControllerState,
}: CreateActionParams): OnEditCollapseData {
  return async () => {
    const [, setState] = getControllerState();

    setState({
      status: FormStatus.REINITIALIZING,
      editCollapseData: true,
      overrideDefaultFieldsValues: true,
    });
  };
}
