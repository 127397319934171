import { PlatformIdentifiers } from '../../../types/types';
import { FormApiContext } from '../utils';
import { createSlotVeloAPIFactory } from '@wix/widget-plugins-ooi/velo';

export const isUpsellPluginInstalled = async ({
  flowApi,
}: FormApiContext): Promise<boolean> => {
  try {
    try {
      const slotAPIFactory = createSlotVeloAPIFactory(flowApi.controllerConfig);
      return (
        slotAPIFactory?.getSlotAPI(
          PlatformIdentifiers.BOOKINGS_FORM_CART_MODAL_SLOT_ID,
        )?.isVisible === true
      ); // should verify if it exactly true
    } catch (e) {
      console.error('slotAPIFactory?.getSlotAPI Failed', e);
      return false;
    }
  } catch (e) {
    console.error('Failed to call isUpsellPluginInstalled', e);
    return false;
  }
};
