import { ContactDetails } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormApiContext } from '../utils';

export type MapContactDetailsArgs = {
  contactDetails: ContactDetails;
};

export const mapContactDetails = ({
  contactDetails,
}: MapContactDetailsArgs &
  Pick<FormApiContext, 'experiments'>): ContactDetails => {
  if (!isPhoneValid(contactDetails.phone!)) {
    delete contactDetails.phone;
  }

  if (contactDetails.lastName) {
    return contactDetails;
  }

  const nameParts = contactDetails?.firstName?.trim().split(/\s/);
  const firstName = nameParts?.slice(0, -1).join(' ').trim();

  const lastName =
    (nameParts?.length || 0) > 1
      ? nameParts?.[nameParts.length - 1]
      : undefined;

  return {
    ...contactDetails,
    ...(firstName && ({ firstName } as { firstName: string | undefined })),
    ...(lastName && ({ lastName } as { lastName: string | undefined })),
  };
};

const isPhoneValid = (phone: string): boolean => {
  if (!phone) {
    return false;
  }
  const phoneValidatorRegEx = /^[\d\s+\-().]+$/;
  return (phone.match(phoneValidatorRegEx)?.length || 0) > 0;
};
