import { PaymentServicesWeb } from '@wix/ambassador-payment-services-web/http';
import {
  PayV2PaymentMethod,
  MethodType,
  PayV2ListPaymentMethodsRequest,
} from '@wix/ambassador-payment-services-web/types';
import { PAYMENT_SERVICES_URL } from '../../FormApi';

export async function hasOnlinePaymentMethods(
  req: PayV2ListPaymentMethodsRequest,
) {
  try {
    const paymentServicesWeb = PaymentServicesWeb(`/${PAYMENT_SERVICES_URL}`, {
      ignoredProtoHttpUrlPart: '/api',
    }).PaymentMethodService();

    return (await paymentServicesWeb().list(req)).paymentMethods?.some(
      function ({ methodType }: PayV2PaymentMethod) {
        return methodType === MethodType.ONLINE;
      },
    );
  } catch (e) {
    console.log('hasConnectedPaymentMethods: FAILED');
  }
  return true;
}
