import { getBookingsAutomationSettings } from '@wix/ambassador-bookings-v1-bookings-automation-settings/http';
import { FormApiContext } from '../utils';
import { BookingsAutomationStatus } from '@wix/ambassador-bookings-v1-bookings-automation-settings/types';

export const SMS_CONFIRMATION = 'b82d645c-eb31-4e55-bf85-f2839abb9ff5';

export const isSMSAutomationsEnabled = async ({
  httpClient,
}: FormApiContext): Promise<boolean> => {
  try {
    const { data } = await httpClient.request(
      getBookingsAutomationSettings({ componentIds: [SMS_CONFIRMATION] }),
    );

    return (
      data.automationSettings?.find(
        (settings) => settings.componentId === SMS_CONFIRMATION,
      )?.status === BookingsAutomationStatus.ACTIVE
    );
  } catch (e) {
    console.error('Failed to call getBookingsAutomationSettings', e);
    return true;
  }
};
