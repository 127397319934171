import { ServerErrorType } from '../../../types/errors';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { Member } from '@wix/ambassador-members-ng-api/types';

export type GetMemberDetailsArgs = {
  id: string;
  onError?: OnError;
};

export const getMemberDetails = async ({
  wixSdkAdapter,
  reportError,
  membersServer,
  authorization,
  id,
  onError,
}: GetMemberDetailsArgs & FormApiContext): Promise<Maybe<Member>> => {
  if (wixSdkAdapter.isEditorMode()) {
    return;
  }
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const membersService = membersServer.Members();
        const { member } = await membersService({
          Authorization: authorization,
        }).getMember({
          fieldSet: 'FULL',
          id,
        });
        return member;
      },
      mapError: (e) => ({
        error: ServerErrorType.GENERIC_MEMBER_DETAILS_ERROR,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }

  return data;
};
