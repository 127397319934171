import {
  bookingsUouBookingsFormClicks,
  bookingsUouBookingsFormClicks1185,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { FormClickAction } from '../../../../types/biLoggerTypes';
import { CreateActionParams } from '../actions';
import { LogObject } from '@wix/bi-logger-wixboost-ugc/v2/types';

export type logBi = (logObjects: LogObject<any>[]) => void;

export function createLogBiAction({
  context: { biLogger },
}: CreateActionParams): logBi {
  return async (logObjects) => {
    await Promise.all(logObjects.map((log) => biLogger?.report(log)));
    await biLogger?.report(
      bookingsUouBookingsFormClicks({ action: FormClickAction.Back }),
    );
    await biLogger?.report(
      bookingsUouBookingsFormClicks1185({ action: FormClickAction.Back }),
    );
  };
}
