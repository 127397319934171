import { ExperimentsConsts } from '../../../consts/experiments';
import { PlatformIdentifiers } from '../../../types/types';
import { FormApiContext } from '../utils';

export const isMembersAreaInstalled = async ({
  flowApi,
}: FormApiContext): Promise<boolean> => {
  const { experiments } = flowApi;

  const isCheckIsMemberAreaInstalledUsingPublicAPIEnabeld = experiments.enabled(
    ExperimentsConsts.CheckIsMemberAreaInstalledUsingPublicAPI,
  );

  try {
    const isAppSectionInstalledArgs = {
      sectionId: PlatformIdentifiers.MEMBER_INFO_SECTION_ID,
      appDefinitionId: PlatformIdentifiers.MEMBER_ACCOUNT_INFO_WIDGET_ID,
    };

    const memberAreaPublicApi =
      await flowApi.controllerConfig.wixCodeApi.site.getPublicAPI(
        PlatformIdentifiers.MEMBERS_AREA_APP_DEF_ID,
      );

    const isMaInstalled = isCheckIsMemberAreaInstalledUsingPublicAPIEnabeld
      ? memberAreaPublicApi.isAppSectionInstalled(isAppSectionInstalledArgs)
      : !!(await flowApi.controllerConfig.wixCodeApi.site.isAppSectionInstalled(
          isAppSectionInstalledArgs,
        ));

    if (isMaInstalled) {
      return true;
    }

    return !!(await flowApi.controllerConfig.wixCodeApi.site.isAppSectionInstalled(
      {
        sectionId: PlatformIdentifiers.MEMBER_PAGE_SECTION_ID,
        appDefinitionId: PlatformIdentifiers.MEMBER_AREA_PAGE,
      },
    ));
  } catch (e) {
    console.error('Failed to call isMembersAreaInstalled', e);
    return false;
  }
};
