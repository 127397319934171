import { ContactDetails } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import {
  ServiceData,
  SlotService,
  SlotServices,
} from '../../../utils/state/types';
import { OnError } from '../../types';
import { Membership } from '@wix/ambassador-totals-calculator/http';
import {
  ChannelType,
  CreateCheckoutResponse,
  CreateCheckoutRequest,
  FullAddressContactDetails,
  ApiAddress,
} from '@wix/ambassador-ecom-v1-checkout/types';
import {
  createCheckout as ecomCreateCheckout,
  createOrder as ecomCreateOrder,
} from '@wix/ambassador-ecom-v1-checkout/http';
import { ExperimentsConsts } from '../../../consts/experiments';
import type { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import { mapContactDetails } from '../mapContactDetails/mapContactDetails';
import { BOOKINGS_APP_DEF_ID } from '../../../utils/flow-api-adapter/consts';
import { mapCheckoutBookingError } from '../../../utils/errors/errors';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import { FormApiContext, withErrorBoundary } from '../utils';
import { CouponDetails } from '../../../types/coupons';

export type GeneralBookingCheckoutFlowArgs = {
  serviceData: ServiceData;
  bookingIds: { [key: string]: string };
  bookingId?: string | null;
  appliedCoupon?: CouponDetails;
  contactDetails: ContactDetails;
  selectedMembership?: Membership;
  onError?: OnError;
  isCart?: boolean;
  country: string;
  couponCheckboxChecked?: boolean;
};

export async function generalBookingCheckoutFlow({
  reportError,
  experiments,
  serviceData,
  bookingIds,
  appliedCoupon,
  contactDetails,
  httpClient,
  onError,
  isCart,
  country,
  couponCheckboxChecked,
}: GeneralBookingCheckoutFlowArgs & FormApiContext) {
  if (Object.keys(bookingIds).length) {
    const { data: createCheckoutResponse, error: createCheckoutError } =
      await withErrorBoundary(
        {
          fn: () =>
            createCheckout(
              experiments,
              serviceData,
              bookingIds,
              appliedCoupon,
              contactDetails,
              country,
              httpClient,
            ),
          mapError: (e) => ({
            error: mapCheckoutBookingError(e?.response),
            shouldReport: true,
          }),
          fallback: {},
        },
        reportError,
      );

    if (createCheckoutError) {
      onError?.(createCheckoutError);
    }

    if (
      shouldNotGoThroughCheckout({
        experiments,
        createCheckoutResponse,
        isCart,
        couponCheckboxChecked,
        slotServices: serviceData.slotServices,
      })
    ) {
      const {
        data: createOrderResponse,
        error: createOrderError,
        message: errorMessage,
      } = await withErrorBoundary(
        {
          fn: () => createOrder(createCheckoutResponse, httpClient),
          mapError: (e) => ({
            error: mapCheckoutBookingError(e?.response),
            shouldReport: true,
          }),
          fallback: {},
        },
        reportError,
      );

      if (createOrderError) {
        onError?.(createOrderError, errorMessage);
      }

      return {
        createCheckoutResponse: createOrderResponse,
        bookingIds,
      };
    } else {
      return {
        createCheckoutResponse,
        bookingIds,
      };
    }
  }
  return {
    bookingIds,
    createCheckoutResponse: {},
  };
}

async function createCheckout(
  experiments: Experiments,
  serviceData: ServiceData,
  bookingIds: { [key: string]: string },
  appliedCoupon: CouponDetails | undefined,
  contactDetails: ContactDetails,
  country: string,
  httpClient: IHttpClient,
) {
  const createCheckoutRequest: CreateCheckoutRequest = {
    channelType: ChannelType.WEB,
    couponCode: appliedCoupon?.couponCode,
    lineItems: [],
    checkoutInfo: {
      billingInfo: {
        contactDetails: mapContactDetails({
          experiments,
          contactDetails,
        }) as FullAddressContactDetails,
        ...(contactDetails.fullAddress && country
          ? {
              address: {
                ...(contactDetails.fullAddress as ApiAddress),
                country,
              },
            }
          : {}),
      },
      buyerInfo: {
        email: contactDetails.email!,
        ...(contactDetails.contactId
          ? { contactId: contactDetails.contactId as string }
          : {}),
      },
      membershipOptions: {
        selectedMemberships: {
          memberships: [],
        },
      },
    },
  };

  mapToArray<SlotService>(serviceData.slotServices).forEach((slotService) => {
    createCheckoutRequest.lineItems?.push({
      quantity: 1,
      id: slotService.nestedSlot.lineItemId,
      catalogReference: {
        catalogItemId:
          bookingIds[getServiceSlotIdentifier(slotService.nestedSlot)],
        appId: BOOKINGS_APP_DEF_ID,
      },
    });
    const selectedMembership =
      slotService.memberships?.eligibleMemberships?.find(
        (membership) => membership?.id === slotService.selectedPaymentOption.id,
      );

    if (selectedMembership) {
      createCheckoutRequest.checkoutInfo?.membershipOptions?.selectedMemberships?.memberships?.push(
        {
          id: selectedMembership.id,
          appId: selectedMembership.appId,
          lineItemIds: [slotService.nestedSlot.lineItemId],
        },
      );
    }
  });

  return (await httpClient.request(ecomCreateCheckout(createCheckoutRequest)))
    .data;
}

function isFreeOrPricePlanCheckoutFlow(
  createCheckoutResponse: CreateCheckoutResponse,
) {
  const payNowAmount = createCheckoutResponse?.checkout?.payNow?.total?.amount;
  const payLaterAmount =
    createCheckoutResponse?.checkout?.payLater?.total?.amount;
  return (
    payNowAmount &&
    Number(payNowAmount) === 0 &&
    payLaterAmount &&
    Number(payLaterAmount) === 0
  );
}

function isOfflineCheckoutFlow(createCheckoutResponse: CreateCheckoutResponse) {
  const payNowAmount = createCheckoutResponse?.checkout?.payNow?.total?.amount;
  return payNowAmount && Number(payNowAmount) === 0;
}

async function createOrder(
  createCheckoutResponse: CreateCheckoutResponse,
  httpClient: IHttpClient,
) {
  return (
    await httpClient.request(
      ecomCreateOrder({ id: createCheckoutResponse.checkout?.id! }),
    )
  ).data;
}

function shouldNotGoThroughCheckout({
  experiments,
  isCart,
  couponCheckboxChecked,
  createCheckoutResponse,
  slotServices = {},
}: {
  experiments: Experiments;
  createCheckoutResponse: CreateCheckoutResponse;
  isCart?: boolean;
  couponCheckboxChecked?: boolean;
  slotServices?: SlotServices;
}) {
  const isCancellationFeesUoUEnabled = experiments.enabled(
    ExperimentsConsts.CancellationFeesUoU,
  );

  const firstService = mapToArray<SlotService>(slotServices)[0];

  const forceCheckout =
    isCancellationFeesUoUEnabled &&
    (firstService.service.hasCancellationFee ||
      firstService.service.hasSaveCreditCardPolicy);

  if (forceCheckout) {
    return false;
  }

  return (
    isFreeOrPricePlanCheckoutFlow(createCheckoutResponse) ||
    (!couponCheckboxChecked && isOfflineCheckoutFlow(createCheckoutResponse))
  );
}
