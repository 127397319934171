import { ServerErrorType } from '../../../types/errors';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { IsAvailableResponse } from '@wix/ambassador-bookings-server/http';

export type GetAvailabilityArgs = {
  scheduleId: string;
  onError?: OnError;
};
export const getAvailability = async ({
  reportError,
  authorization,
  bookingsServer,
  scheduleId,
  onError,
}: GetAvailabilityArgs & FormApiContext): Promise<IsAvailableResponse> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: () =>
        bookingsServer
          .Availability()({ Authorization: authorization })
          .isAvailable({
            scheduleId,
            partySize: 1,
          }),
      mapError: (e) => ({
        error: ServerErrorType.NO_COURSE_AVAILABILITY,
        shouldReport: true,
      }),
      fallback: {
        capacity: 1,
        totalNumberOfParticipants: 1,
      },
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }
  return data;
};
