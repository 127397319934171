import { IWixAPI } from '@wix/native-components-infra/dist/es/src/types/types';
import { isAnywhereUrl } from '@wix/wix-anywhere-api';

export const isDayfulFlow = async (
  wixCodeApi: IWixAPI,
  isPreview?: boolean,
): Promise<boolean> => {
  if (isPreview) {
    return false;
  }

  try {
    const isAnywhere = await isAnywhereUrl({
      fullUrl: wixCodeApi.location.url,
      externalBaseUrl: wixCodeApi.location.baseUrl,
    });
    return isAnywhere || false;
  } catch (e) {
    return false;
  }
};
