import { CartModalStatus } from '../../../../types/types';
import { CreateActionParams } from '../actions';

export type OpenCartDialogForEditorView = () => void;

export function createOpenCartDialogForEditorViewAction({
  getControllerState,
}: CreateActionParams): OpenCartDialogForEditorView {
  return () => {
    const [, setState] = getControllerState();

    setState({
      cartModal: {
        status: CartModalStatus.OPEN,
        lineItems: [],
      },
    });
  };
}
