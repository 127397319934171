import { BusinessInfo } from '../../types/types';
import { TimezoneType } from '@wix/bookings-uou-types';

export const getDefaultTimezone = (businessInfo?: BusinessInfo): string => {
  const localTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const businessTimezone = businessInfo!.timeZone!;
  const defaultTimezoneType = businessInfo?.timezoneProperties?.defaultTimezone;
  return defaultTimezoneType === TimezoneType.CLIENT
    ? localTimezone
    : businessTimezone;
};

export const getCurrentTimezone = ({
  pageApiTimezone,
  businessInfo,
}: {
  pageApiTimezone?: string;
  businessInfo: BusinessInfo;
}) => {
  return pageApiTimezone || getDefaultTimezone(businessInfo);
};
