import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { ExtendedBooking } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { mapToArray } from '../../../utils';
import { SlotService, SlotServices } from '../../../utils/state/types';
import { FormApiContext } from '../utils';
import { ExperimentsConsts } from '../../../consts/experiments';

export interface GetExistingBookingArgs {
  slotServices: SlotServices;
  contactId?: string;
}

export const getExistingBooking = async ({
  slotServices,
  contactId,
  queryBookingsByServiceId,
  experiments,
}: GetExistingBookingArgs & FormApiContext): Promise<
  ExtendedBooking | undefined
> => {
  const slotService = mapToArray<SlotService>(slotServices)[0];
  const isCheckForExistingBookingEnabled = experiments.enabled(
    ExperimentsConsts.CheckForExistingBooking,
  );

  if (!isCheckForExistingBookingEnabled) {
    return undefined;
  }

  if (
    slotService.service.type !== ServiceType.CLASS &&
    slotService.service.type !== ServiceType.COURSE
  ) {
    return undefined;
  }

  const relatedBooking = await queryBookingsByServiceId({
    serviceIds: [slotService.service.id],
    contactId,
  });

  return relatedBooking.find((existExtendedBooking) => {
    const isExistingBookingForClass =
      slotService.service.type === ServiceType.CLASS &&
      existExtendedBooking?.booking?.bookedEntity?.slot?.startDate ===
        slotService.nestedSlot.startDate;

    const isExistingBookingForCourse =
      slotService.service.type === ServiceType.COURSE;

    return isExistingBookingForClass || isExistingBookingForCourse;
  });
};
