import { CartFlow } from '../../../../types/types';
import { CreateActionParams } from '../actions';
import { bookingsUouSessionAddedToCartModalInteractions } from '@wix/bi-logger-wixboost-ugc/v2';
import { CartModalInteractionActionName } from '../../../../types/biLoggerTypes';
import { SlotService } from '../../../../utils/state/types';
import settingsParams from '../../settingsParams';

export type OnCartCTAClick = (
  service: SlotService,
  cartFlow: CartFlow,
) => Promise<void>;

export function createOnCartCTAClickAction({
  context,
  getControllerState,
}: CreateActionParams): OnCartCTAClick {
  return async (service: SlotService, cartFlow: CartFlow) => {
    const { wixSdkAdapter, biLogger, settings } = context;
    const [state] = getControllerState();
    const { cartModal } = state;

    if (cartFlow === CartFlow.ADD_MORE_SESSIONS) {
      biLogger?.report(
        bookingsUouSessionAddedToCartModalInteractions({
          ...cartModal?.biLoggerData,
          actionName: CartModalInteractionActionName.AddMoreSessions,
        }),
      );

      const continueShoppingLink = settings.get(
        settingsParams.cartPopupWhereToLinkContinueBrowsing,
      );
      await wixSdkAdapter.navigateToContinueShopping(continueShoppingLink);
    } else if (
      cartFlow === CartFlow.CHECKOUT ||
      cartFlow === CartFlow.CLOSE_MODAL_OVERLAY ||
      cartFlow === CartFlow.CLOSE_MODAL
    ) {
      let cartModalBiAction = CartModalInteractionActionName.GoToViewCart;

      if (cartFlow === CartFlow.CLOSE_MODAL) {
        cartModalBiAction = CartModalInteractionActionName.Close;
      } else if (cartFlow === CartFlow.CLOSE_MODAL_OVERLAY) {
        cartModalBiAction = CartModalInteractionActionName.OverlayClose;
      }

      biLogger?.report(
        bookingsUouSessionAddedToCartModalInteractions({
          ...cartModal?.biLoggerData,
          actionName: cartModalBiAction,
        }),
      );

      await wixSdkAdapter.navigateToEcomCart();
    } else {
      throw new Error('CartFlow cannot be undefined after adding to cart');
    }
  };
}
