import { ServerErrorType } from '../../../types/errors';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { getServiceSlotIdentifier } from '../../../utils';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { queryServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/http';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';

export type GetOptionsAndVariantsDataArgs = {
  formSelectedSlot: FormSelectedSlot;
  onError?: OnError;
};
export const getOptionsAndVariantsData = async ({
  formSelectedSlot,
  httpClient,
  reportError,
}: GetOptionsAndVariantsDataArgs & FormApiContext): Promise<{
  [key: string]: ServiceOptionsAndVariants | undefined;
}> => {
  const serviceIds = formSelectedSlot.nestedSlots.map((slot) => slot.serviceId);
  const { data: serviceOptionsAndVariantsList } = await withErrorBoundary(
    {
      fn: async () => {
        const { data: response } = await httpClient.request(
          queryServiceOptionsAndVariants({
            query: {
              filter: {
                serviceId: {
                  $in: serviceIds,
                },
              },
            },
          }),
        );

        return response?.serviceOptionsAndVariantsList;
      },
      mapError: (e) => ({
        error: ServerErrorType.OPTIONS_AND_VARIANTS_FAILED,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  return formSelectedSlot.nestedSlots?.reduce((acc, slot) => {
    acc[getServiceSlotIdentifier(slot)] = serviceOptionsAndVariantsList?.find(
      (variant) => variant.serviceId === slot.serviceId,
    );
    return acc;
  }, {} as { [key: string]: ServiceOptionsAndVariants | undefined });
};
