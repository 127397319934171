import { FormApiContext, withErrorBoundary } from '../utils';
import { OnError, ServiceInfo } from '../../types';
import { queryBookingPolicies } from '@wix/ambassador-bookings-v1-booking-policy/http';
import { ServerErrorType } from '../../../types/errors';

export type GetPolicyStringToUseArgs = {
  serviceList: ServiceInfo[];
  onError: OnError;
};

export const getPolicyStringToUse = async ({
  wixSdkAdapter,
  onError,
  serviceList,
  httpClient,
  reportError,
}: GetPolicyStringToUseArgs & FormApiContext): Promise<string | undefined> => {
  if (wixSdkAdapter.isEditorMode()) {
    return;
  }

  const policiesIds = serviceList
    .map((service) => service.service?.bookingPolicy?.id)
    .filter((id): id is string => !!id);

  if (areAllValuesIdentical(policiesIds)) {
    return serviceList[0].service?.bookingPolicy?.customPolicyDescription
      ?.description;
  }

  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const policy = await httpClient.request(
          queryBookingPolicies({
            query: {
              filter: { default: true },
            },
          }),
        );
        return policy.data;
      },
      mapError: (e) => ({
        error: ServerErrorType.FAILED_TO_FETCH_DEFAULT_BOOKING_POLICY,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  if (error) {
    onError(error);
  }

  return data?.bookingPolicies?.[0].customPolicyDescription?.description;
};

const areAllValuesIdentical = (arr: string[]) => {
  if (arr.length === 0) {
    // Empty array is considered to have identical values
    return true;
  }

  const firstValue = arr[0];

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== firstValue) {
      return false; // Found a different value
    }
  }

  return true; // All values are identical
};
