import { FormApiContext } from '../utils';
import { QueryAvailabilityRequest } from '@wix/ambassador-availability-calendar/types';

export type GetAppointmentAvailabilityArgs = {
  serviceId: string;
  resourceId: string;
  locationId?: string;
  startDate: string;
  endDate: string;
  timezone: string;
  beginningOfStartDate: string;
};

export const getAppointmentAvailability = async ({
  serviceId,
  resourceId,
  locationId,
  startDate,
  endDate,
  timezone,
  beginningOfStartDate,
  availabilityCalendarServer,
  authorization,
}: GetAppointmentAvailabilityArgs & FormApiContext) => {
  const request = {
    timezone,
    query: {
      filter: {
        serviceId,
        startDate: beginningOfStartDate,
        endDate,
        resourceId,
        'location.businessLocation.id': locationId ? locationId : undefined,
        bookable: true,
      },
    },
  } as QueryAvailabilityRequest;

  const availabilityServer = availabilityCalendarServer.AvailabilityCalendar()({
    Authorization: authorization,
  });

  const availabilityResponse = await availabilityServer.queryAvailability(
    request,
  );

  const startTimestamp = new Date(startDate).valueOf();
  const endTimestamp = new Date(endDate).valueOf();

  // we need to find the right slot because there is a time padding on the server
  const slot = availabilityResponse.availabilityEntries?.find(
    (availabilitySlot) =>
      new Date(availabilitySlot.slot?.startDate || 0).valueOf() ===
        startTimestamp &&
      new Date(availabilitySlot.slot?.endDate || 0).valueOf() === endTimestamp,
  );

  return slot;
};
