import { CreateActionParams } from '../actions';
import { bookingsUouBookingsFormConflictNoteShownOnPage } from '@wix/bi-logger-wixboost-ugc/v2';

export type OnConflictAlertShown = (message: string) => void;

export function createOnConflictAlertShownAction({
  context: { biLogger },
}: CreateActionParams): OnConflictAlertShown {
  return async (message: string) => {
    biLogger?.report(
      bookingsUouBookingsFormConflictNoteShownOnPage({
        noteMessagae: message,
      }),
    );
  };
}
