import {
  Service,
  Location as ServiceLocation,
  LocationType as ServiceLocationType,
  BusinessLocationOptions,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { FormLocation } from '../service.mapper';
import {
  BookedLocation,
  LocationType,
} from '../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';

interface BusinessLocationGeoCode {
  latitude: number;
  longitude: number;
}

interface BusinessLocationStreetAddress {
  name?: string;
  number?: string;
  apt?: string;
}

export interface BusinessLocationAddress {
  formattedAddress?: string;
  geocode?: BusinessLocationGeoCode;
  streetAddress?: BusinessLocationStreetAddress;
  city?: string;
  subdivision?: string;
  country?: string;
  postalCode?: string;
}

export interface BusinessLocation extends BusinessLocationOptions {
  address?: BusinessLocationAddress;
}

export const mapServiceLocations = (
  service: Service,
  slotLocation?: BookedLocation,
): FormLocation => {
  const serviceLocation = service.locations?.[0];
  const isCourse = service.type === ServiceType.COURSE;
  const location = isCourse
    ? mapServiceLocationToFormLocation(serviceLocation)
    : mapSlotLocationToLocation(slotLocation);

  return location;
};

const mapServiceLocationToFormLocation = (
  location?: ServiceLocation,
): FormLocation => {
  let address = location?.business?.address?.formattedAddress || '';

  if (!location) {
    return {
      locationType: LocationType.UNDEFINED,
    };
  }

  if (location.type !== ServiceLocationType.BUSINESS) {
    address =
      location.calculatedAddress?.formattedAddress! ||
      location.custom?.address?.addressLine! ||
      '';
  }

  return {
    id: location.business?.id,
    name: location.business?.name,
    address,
    locationType: mapLocationType(location.type),
  };
};

const mapSlotLocationToLocation = (location?: BookedLocation): FormLocation => {
  return {
    id: location?.id,
    name: location?.name,
    address: location?.formattedAddress,
    locationType: location!.locationType!,
  };
};

const mapLocationType = (locationType?: ServiceLocationType): LocationType => {
  switch (locationType) {
    case ServiceLocationType.BUSINESS:
      return LocationType.OWNER_BUSINESS;
    case ServiceLocationType.CUSTOM:
      return LocationType.OWNER_CUSTOM;
    case ServiceLocationType.CUSTOMER:
      return LocationType.CUSTOM;
    default:
      return LocationType.UNDEFINED;
  }
};
