import {
  ContactDetails,
  CustomFormField,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { CartFlow } from '../../../types/types';
import { mapCheckoutBookingError } from '../../../utils/errors/errors';
import { ServiceData } from '../../../utils/state/types';
import { addToCart } from '../addToCart/addToCart';
import { generalBookingCheckoutFlow } from '../generalBookingCheckoutFlow/generalBookingCheckoutFlow';
import { FormApiContext, withErrorBoundary } from '../utils';
import { createBookings } from './createBookings';
import { AddToCartResponse } from '@wix/ambassador-ecom-v1-cart/types';
import { OnError } from '../../types';
import {
  CreateCheckoutResponse,
  CreateOrderResponse,
} from '@wix/ambassador-ecom-v1-checkout/types';
import { CouponDetails } from '../../../types/coupons';

export type CheckoutBookingArgs = {
  serviceData: ServiceData;
  contactDetails: ContactDetails;
  additionalFields: CustomFormField[];
  numberOfParticipants?: number;
  sendSmsReminder?: boolean;
  appliedCoupon?: CouponDetails;
  isCart?: boolean;
  cartFlow?: CartFlow;
  onError?: OnError;
  country: string;
  couponCheckboxChecked?: boolean;
};
export const checkoutBooking = async ({
  reportError,
  serviceData,
  contactDetails,
  additionalFields,
  numberOfParticipants,
  sendSmsReminder,
  appliedCoupon,
  isCart,
  cartFlow,
  onError,
  country,
  couponCheckboxChecked,
  ...rest
}: CheckoutBookingArgs & FormApiContext): Promise<{
  bookingIds?: { [key: string]: string };
  createCheckoutResponse?: CreateCheckoutResponse | CreateOrderResponse;
  addToCurrentCartResponse?: AddToCartResponse;
}> => {
  const { data: bookingIds, error: createBookingsError } =
    await withErrorBoundary(
      {
        fn: () =>
          createBookings({
            serviceData,
            contactDetails,
            additionalFields,
            numberOfParticipants,
            sendSmsReminder,
            reportError,
            ...rest,
          }),
        mapError: (e) => ({
          error: mapCheckoutBookingError(e?.response),
          shouldReport: true,
        }),
        fallback: {},
      },
      reportError,
    );

  if (createBookingsError) {
    onError?.(createBookingsError);
  }

  if (
    isCart &&
    Object.keys(bookingIds).length &&
    cartFlow === CartFlow.ADD_MORE_SESSIONS
  ) {
    try {
      return addToCart({
        serviceData,
        bookingIds,
        onError,
        reportError,
        ...rest,
      });
    } catch (e: any) {
      if (e.httpStatus === 404) {
        return addToCart({
          serviceData,
          bookingIds,
          onError,
          reportError,
          ...rest,
        });
      } else {
        onError?.(e.message);
      }
    }
  } else {
    return generalBookingCheckoutFlow({
      couponCheckboxChecked,
      serviceData,
      bookingIds,
      appliedCoupon,
      contactDetails,
      onError,
      isCart,
      country,
      reportError,
      ...rest,
    });
  }
  return {
    bookingIds,
    createCheckoutResponse: {},
  };
};
