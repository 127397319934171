import { queryExtendedBookings } from '@wix/ambassador-bookings-reader-v2-extended-booking/http';
import { FormApiContext } from '../utils';
import {
  ExtendedBooking,
  BookingStatus,
} from '@wix/ambassador-bookings-reader-v2-extended-booking/types';

export interface QueryBookingsByServiceIdArgs {
  serviceIds: string[];
  contactId?: string;
}

export const getConfirmedBookingsByServiceAndContactId = async ({
  serviceIds,
  contactId,
  httpClient,
}: QueryBookingsByServiceIdArgs & FormApiContext): Promise<
  ExtendedBooking[]
> => {
  let listResponse;

  try {
    listResponse = await httpClient.request(
      queryExtendedBookings({
        query: {
          filter: {
            $or: [
              { 'bookedEntity.item.slot.serviceId': { $in: serviceIds } },
              { 'bookedEntity.item.schedule.serviceId': { $in: serviceIds } },
            ],
            startDate: { $gte: new Date().toISOString() },
            status: BookingStatus.CONFIRMED,
            ...(contactId ? { 'contactDetails.contactId': contactId } : {}),
          },
        },
      }),
    );

    return listResponse?.data?.extendedBookings || [];
  } catch (e) {
    return [];
  }
};
