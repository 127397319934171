import { Form } from '../../../types/ambassador/bookings/ambassador-services-catalog';
import { ServerErrorType } from '../../../types/errors';
import { ServiceInfo } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { get } from '@wix/ambassador-bookings-v1-form/http';

export const DefaultFormID = '00000000-0000-0000-0000-000000000000';

export type getFormArgs = {
  services: ServiceInfo[];
};

export const getForm = async ({
  services,
  httpClient,
  reportError,
}: getFormArgs & FormApiContext): Promise<Form | undefined> => {
  let formIdToUse =
    services.length === 1 ? services[0].service.form?.id! : DefaultFormID;

  // In case there is no form, we are falling back to the default form
  if (!formIdToUse) {
    formIdToUse = DefaultFormID;
  }
  const { data } = await withErrorBoundary(
    {
      fn: async () => {
        return httpClient.request(
          get({
            id: formIdToUse,
          }),
        );
      },
      mapError: (e) => ({
        error: ServerErrorType.FAILED_TO_FETCH_DEFAULT_FORM_DATA,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  return data?.data?.form as Form;
};
