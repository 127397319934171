import { FormContext } from '../../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../../utils/state/types';
import { FormErrorsParams, FormErrorsType } from '../../../../../types/errors';

export type AddError = (
  error: FormErrorsType,
  params?: FormErrorsParams,
) => void;

export function createAddErrorAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): AddError {
  return (error: FormErrorsType, params?: FormErrorsParams) => {
    const [state, setState] = getControllerState();

    const { errors: prevFormErrors } = state;

    const isErrorAlreadyExists = !!prevFormErrors.find(
      (e) => e.errorType === error,
    );
    if (!isErrorAlreadyExists) {
      setState({
        errors: [
          ...prevFormErrors,
          { errorType: error, ...(params ? { params } : {}) },
        ],
      });
    }
  };
}
