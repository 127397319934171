import { SelectedVariants } from '@wix/bookings-uou-types';
import { CreateActionParams } from '../actions';
import { PriceOptionNumberOfParticipants } from '../../Widget/PriceOptionDropdownContainer/PriceOptionDropdownContainer';

export type OnSelectedVariants = (
  priceOptionData: PriceOptionNumberOfParticipants[],
  optionId: string,
  serviceSlotId: string,
) => void;

export function createOnSelectedVariantsAction({
  getControllerState,
  internalActions: { calculatePaymentDetails, updateServiceSlot },
}: CreateActionParams): OnSelectedVariants {
  return async (
    priceOptionData: PriceOptionNumberOfParticipants[],
    optionId: string,
    serviceSlotId: string,
  ) => {
    const [state] = getControllerState();
    const { serviceData } = state;
    const dynamicPriceInfo =
      serviceData.slotServices[serviceSlotId].dynamicPriceInfo;
    const selectedVariants: SelectedVariants[] = priceOptionData.reduce(
      (
        variants: SelectedVariants[],
        option: PriceOptionNumberOfParticipants,
      ) => {
        return option?.numberOfParticipants
          ? [
              ...variants,
              {
                numberOfParticipants: option?.numberOfParticipants,
                choices: [{ optionId, custom: option.title }],
              },
            ]
          : variants;
      },
      [] as SelectedVariants[],
    );

    updateServiceSlot(
      {
        dynamicPriceInfo: {
          ...dynamicPriceInfo,
          selectedVariants,
        },
      },
      serviceSlotId,
    );

    await calculatePaymentDetails({});
  };
}
