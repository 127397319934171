import {
  BookingLineItem,
  PreviewPriceRequest,
  PreviewPriceResponse,
} from '@wix/ambassador-bookings-v2-price-info/types';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { mapPreviewServerError } from '../../../utils/errors/errors';
import { previewPrice as previewPriceHttpRequest } from '@wix/ambassador-bookings-v2-price-info/http';
import { ServerErrorType } from '../../../types/errors';

export type PreviewPriceArgs = {
  bookingLineItems: BookingLineItem[];
  onError?: OnError;
};

export const previewPrice = async ({
  httpClient,
  reportError,
  bookingLineItems,
  onError,
}: PreviewPriceArgs & FormApiContext): Promise<PreviewPriceResponse> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const previewPriceRequest: PreviewPriceRequest = {
          bookingLineItems,
        };
        const { data: previewPriceResponse } = await httpClient.request(
          previewPriceHttpRequest(previewPriceRequest),
        );
        return previewPriceResponse;
      },
      mapError: (e) => ({
        error: mapPreviewServerError(JSON.stringify(e)),
        shouldReport: true,
      }),
      fallback: {},
    },
    reportError,
  );

  if (error) {
    onError?.(error as unknown as ServerErrorType);
  }

  return data;
};
