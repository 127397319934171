import { ServerErrorType } from '../../../types/errors';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import {
  ListSlotsRequest,
  ListSlotsResponse,
} from '@wix/ambassador-calendar-server/http';

export type GetSlotsArgs = {
  firstSessionStart: string;
  lastSessionEnd: string;
  scheduleId: string;
  onError?: OnError;
};
export const getSlots = async ({
  reportError,
  calendarServer,
  authorization,
  firstSessionStart,
  lastSessionEnd,
  scheduleId,
  onError,
}: GetSlotsArgs & FormApiContext): Promise<ListSlotsResponse> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: () => {
        const calendarService = calendarServer.CalendarService();
        // @ts-expect-error
        const fields: string[] = null;
        // @ts-expect-error
        const fieldsets: string[] = null;
        const filter = {
          from: new Date(firstSessionStart).toISOString(),
          to: new Date(lastSessionEnd).toISOString(),
          scheduleIds: [scheduleId],
        };
        const request: ListSlotsRequest = {
          query: {
            fieldsets,
            fields,
            sort: [],
            filter: JSON.stringify(filter),
          },
        };

        const calendarServiceResponse = calendarService({
          Authorization: authorization,
        });
        return calendarServiceResponse.listSlots(request);
      },
      mapError: (e) => {
        return {
          error: ServerErrorType.NO_LIST_SLOTS,
          shouldReport: true,
        };
      },
      fallback: {
        slots: undefined,
      },
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }

  return data;
};
